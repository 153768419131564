<template>
  <v-container fill-height fluid grid-list-xl pb-5 mb-5>
    <v-layout column white wrap ma-2>
      <!-- CONNECTED USER'S CUSTOMER'S PROJECTS -->
      <v-flex pt-2 pb-0 px-4 d-inline-flex>
        <div>
          <v-btn v-if="project.id" flat color="primary" @click="goToProject">
            <span class="text-truncate" style="max-width:12rem">
              {{ project.name }}
            </span>
          </v-btn>
          <v-icon v-if="project.id" small>
            mdi-arrow-right
          </v-icon>
          <v-btn v-if="sprint.id" flat color="primary" @click="goToSprint">
            <span class="text-truncate" style="max-width:12rem">
              {{ sprint.name }}
            </span>
          </v-btn>
          <v-icon v-if="sprint.id" small>
            mdi-arrow-right
          </v-icon>
          <v-btn flat color="primary">
            <span class="text-truncate" style="max-width:12rem">
              {{ userStory.name }}
            </span>
          </v-btn>
        </div>
      </v-flex>
      <v-divider />
      <v-flex px-4>
        <h2>
          {{ userStory.name }}
        </h2>
        <p
          v-if="userStory.comment"
          style="white-space: pre-line"
          v-html="userStory.comment"
        />
      </v-flex>
      <v-flex>
        <v-flex right py-0>
          <v-btn
            v-if="isAdmin"
            center
            class="success"
            @click="addUserAcceptanceTest"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
            <v-flex ml-2 pa-0>
              Ajouter une UAT
            </v-flex>
          </v-btn>
        </v-flex>
        <v-flex left py-0>
          <v-btn center class="quaternary" @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
            <v-flex ml-2 pa-0>
              Retour au sprint
            </v-flex>
          </v-btn>
        </v-flex>
      </v-flex>
      <material-DataTableUserAcceptanceTest
        :items="userAcceptanceTests"
        :loading="loading"
        can-search
        @click:row="userAcceptanceTestDetails"
        @click:edit="editUserAcceptanceTest"
        @click:delete="deleteUserAcceptanceTest"
      />
      <v-flex>
        <v-card>
          <v-card-title>
            <h5>
              <v-icon color="info">
                mdi-help-circle-outline
              </v-icon>
              Aide
            </h5>
          </v-card-title>
          <v-flex>
            <v-flex ml-2>
              Vos User Stories sont découpées en User Acceptance Tests. Chaque
              UAT doit être recettée pour que l'US soit recettée.
            </v-flex>
            <v-flex ml-2>
              <ul>
                <li>
                  <v-icon color="info">
                    <!-- progress 3 -->
                    mdi-alarm-light
                  </v-icon>
                  Cette UAT est en attente de recette de votre part.
                </li>
                <li>
                  <v-icon color="success">
                    <!-- progress 2 -->
                    mdi-check-circle
                  </v-icon>
                  Cette UAT a été recettée. Vous ne pouvez plus la modifier.
                </li>
                <li>
                  <v-icon color="warning">
                    <!-- progress 1 -->
                    mdi-alert-circle
                  </v-icon>
                  Cette UAT a été recettée avec réserve. Vous ne pouvez plus la
                  modifier tant que elle n'est pas de nouveau en attente.
                </li>
                <li>
                  <v-icon color="error">
                    <!-- progress 0 -->
                    mdi-close-circle
                  </v-icon>
                  La recette de cette UAT a été refusée. Vous ne pouvez plus la
                  modifier tant que elle n'est pas de nouveau en attente.
                </li>
              </ul>
            </v-flex>
          </v-flex>
        </v-card>
      </v-flex>

      <ui-DialogBox
        title="Suppression d'une UAT"
        msg="Voulez-vous vraiment supprimer cette UAT ?  Attention, s'il reste des commentaires, ils seront peut être supprimer."
        :show="userAcceptanceTestToDelete > 0"
        icon-title="mdi-delete-outline"
        cancel-icon="close-circle-outline"
        ok-icon="check-circle-outline"
        @cancel="userAcceptanceTestToDelete = null"
        @ok="confirmDeleteUserAcceptanceTest(userAcceptanceTestToDelete)"
      />

      <!-- SNACKBAR -->
      <ui-SnackBarV2
        :text="text"
        :color="color"
        :bottom="bottom"
        :top="top"
        :right="right"
        :left="left"
        :snackbar="snackbar"
      />
    </v-layout>
  </v-container>
</template>
<script>
import RoleMixin from '@/mixins/RoleMixin';
import SnackBarMixin from '@/mixins/SnackBarMixin';

export default {
  name: 'UserStoryDetails',

  mixins: [RoleMixin, SnackBarMixin],

  data: () => ({
    userStoryId: null,
    loading: false,
    userAcceptanceTestToDelete: null,
  }),

  computed: {
    project() {
      return this.$store.getters['project/getProject']() || null;
    },

    sprint() {
      return this.$store.getters['sprint/getSprint']() || null;
    },

    userStory() {
      return this.$store.getters['userStory/getUserStory']() || null;
    },

    userAcceptanceTests() {
      return (
        this.$store.getters[
          'userAcceptanceTest/getUserStoryUserAcceptanceTests'
        ]() || []
      );
    },
  },

  created() {
    this.userStoryId = this.$route.params.id;
  },

  mounted() {
    // get the sprint and the user stories associate
    this.$store.commit('app/showLoader');
    Promise.all([
      this.$store.dispatch(
        'userStory/get_user_stories_by_id',
        this.userStoryId
      ),
      this.$store.dispatch(
        'userAcceptanceTest/get_user_story_user_acceptance_tests',
        this.userStoryId
      ),
    ])
      .then((response) => {
        console.log('UserStory OK', response[0]);
        console.log('UserStory UserAcceptanceTests OK', response[1]);
        // get the sprint associate to the User Story
        if (
          this.sprint &&
          parseInt(this.project.id) !== parseInt(this.userStory.sprints_id)
        ) {
          this.$store
            .dispatch('sprint/get_sprint_by_id', this.userStory.sprints_id)
            .then((response) => {
              console.log('Sprint OK', response);
            });
        }

        // get the project associate to the sprint
        if (
          this.project &&
          parseInt(this.project.id) !== parseInt(this.userStory.projects_id)
        ) {
          this.$store
            .dispatch('project/get_project_by_id', this.userStory.projects_id)
            .then((response) => {
              console.log('Project OK', response);
            });
        }
      })
      .catch((error) => {
        console.log('Erreur', error);
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text:
            'Impossible de récupérer les user stories du sprint : ' +
            error.message,
        });
      })
      .finally(() => {
        this.$store.commit('app/showLoader');
        this.$store.commit('app/resetDashboardIds');
        this.$store.commit('app/setDashboardIds', {
          item: 'project',
          id: this.userStory.projects_id,
        });
        this.$store.commit('app/setDashboardIds', {
          item: 'sprint',
          id: this.userStory.sprints_id,
        });
        this.$store.commit('app/setDashboardIds', {
          item: 'userStory',
          id: this.userStory.id,
        });
      });
  },

  methods: {
    goBack() {
      this.$router.push({ path: `/sprint/${this.userStory.sprints_id}` });
    },

    goToProject() {
      this.$router.push({ path: `/project/${this.userStory.projects_id}` });
    },

    goToSprint() {
      this.$router.push({ path: `/sprint/${this.userStory.sprints_id}` });
    },

    userAcceptanceTestDetails(id) {
      console.log('userStoryDetails', id);
      this.$router.push({ path: `/userAcceptanceTest/${id}` });
    },

    addUserAcceptanceTest() {
      console.log('addUserStory');
      this.$router.push({ path: `/userAcceptanceTest/create` });
    },

    editUserAcceptanceTest(id) {
      console.log('editUserAcceptanceTest', id);
      this.$router.push({ path: `/userAcceptanceTest/${id}/edit` });
    },

    deleteUserAcceptanceTest(id) {
      console.log('deleteUserAcceptanceTest', id);
      this.userAcceptanceTestToDelete = id;
    },
    confirmDeleteUserAcceptanceTest(id) {
      console.log('confirmDeleteUserAcceptanceTest', id);
      this.$store
        .dispatch('userAcceptanceTest/delete_user_acceptance_tests_by_id', id)
        .then(() => {
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'success',
            text: 'User acceptance test supprimé avec succès',
          });
        })
        .catch((error) => {
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text:
              'Impossible de supprimer la user acceptance test : ' +
              error.message,
          });
        })
        .finally(() => {
          this.userAcceptanceTestToDelete = null;
        });
    },
  },
};
</script>

<style></style>
